/* Base and Variables*/
@font-face {
  font-family: 'Open Sans';
  src: url(src/fonts/open-sans/OpenSans-Regular.ttf); }

@font-face {
  font-family: 'Open Sans Bold';
  src: url(src/fonts/open-sans/OpenSans-Bold.ttf); }

:root {
  font-size: 62.5%; }

html,
body {
  height: 100%; }

body {
  font-family: 'Open Sans';
  background-color: #f6f9ff;
  height: 100%; }
  body #app {
    height: 100%; }

* {
  box-sizing: border-box; }

.ps__rail-x,
.ps__rail-y {
  opacity: 1 !important; }

/* Components */
.idleMonitor--working {
  display: none; }
